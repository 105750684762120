
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600;700&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   background: var(--bg-body);
   background-color: rgb(101, 79, 101);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root{
  --card-color:#8384B2;
  --bg-body:#ebebeb;
 --ff-poppins: 'Poppins', sans-serif;
}
#root{
  max-width: 1440px;
margin-inline: auto;
  font-family: var(--ff-poppins);
}
.navigation{
  display: flex;
  padding:20px 40px;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  margin-inline: auto;
}
.navigation h2{
  color: rgba(11, 159, 159, 0.987);
  font-size: 26px;
}
.navigation-links{
  width:900%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 30px;
}
.navigation-links >*{
  text-decoration: none;
}
.user-list{
  display: grid;
  gap:30px;
  grid-template-columns: repeat(auto-fill,minmax(500px,1fr));
  

}.user{
  height: 200px;
  /* object-fit: contain; */
  /* box-shadow: 0 0 2px 1px gray; */
  border-radius: 5px;
  padding:10px;

  /* background: var(--card-color); */

  /* background: rgba(11, 159, 159, 0.4); */
  background: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  color: rgba(0, 0, 0, 0.353);
  cursor: pointer;

}
.user:hover{
  box-shadow: 0 0 2px 1px gray
}
.user img{
  width: 70px;
  height:70px;
  border-radius: 50%;
  position: absolute;
  top:0x;
 left:50%;
 transform: translate(-50%,-50%);
 object-fit: contain;

}
.user .user-name{
  text-transform: uppercase;
  color: #000;
  font-weight: 500;
  margin-top: 50px;
  font-size: 12px;
}
.user .email{
  font-size: 13px;
  font-style: italic;
}
.pagination{
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}
.pagination .buttons button{
  padding:16px 32px;
   box-shadow: 0 2px 2px  gray;
  border-radius: 4px;
  border: none;
  background: #fff;
  margin-right: 10px;
}
button{
  cursor: pointer;
}
button:hover{
  opacity: .5;
}
.pages{
   display: grid;
  
  gap:10px;
  grid-template-columns: repeat(auto-fill,minmax(40px,1fr));
  


 
  padding: 2px;
  /* border: 1px solid #000; */
}
.pages button{
  padding: 14px;
   box-shadow: 0 2px 2px  gray;
  border-radius: 4px;
  border: none;
  background: #fff;
}